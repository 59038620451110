.ticket-container p {
  margin: 0;
  padding: 0;
}

.header-padding {
  padding-top: 6px;
  padding-bottom: 8px;
}
.request {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1rem;
  border: 1px #ff0062 solid;
  border-radius: 30px;
  background-color: #fff;
  cursor: pointer;
}

.request-text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #ff0062;
  margin-left: 6px;
}

.request-list {
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #000000;
  margin-bottom: 10px;
  margin-top: 35px;
}

table {
  border-collapse: collapse;
  width: 100%;
  transition: all 0.3s ease;
  tr:hover {
    background-color: #f0f6f9;
  }
  th,
  td {
    padding: 13.5px 10px;
  }
  th {
    text-align: left;
    background: #eceef1;
    color: #878f9b;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }
  td {
    color: #0f0f0f;
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
  }
}
.tableRow {
  height: 46px;
  border: 1px solid #c4c6c9;
  &:nth-child(1) {
    width: 45%;
    min-width: 45%;
  }
  &:nth-child(2) {
    width: 20%;
    min-width: 20%;
  }
  &:nth-child(3) {
    width: 15%;
    min-width: 15%;
  }
  &:nth-child(4) {
    width: 20%;
    min-width: 20%;
  }
}

.table2Row {
  height: 46px;
  border: 1px solid #c4c6c9;
  &:nth-child(1) {
    width: 22%;
    min-width: 22%;
  }
  &:nth-child(2) {
    width: 33%;
    min-width: 33%;
  }
  &:nth-child(3) {
    width: 15%;
    min-width: 15%;
  }
  &:nth-child(4) {
    width: 15%;
    min-width: 15%;
  }
  &:nth-child(5) {
    width: 15%;
    min-width: 15%;
  }
}

.table3Row {
  height: 46px;
  border: 1px solid #c4c6c9;
  &:nth-child(1) {
    width: 15%;
    min-width: 15%;
  }
  &:nth-child(2) {
    width: 18%;
    min-width: 18%;
  }
  &:nth-child(3) {
    width: 15%;
    min-width: 15%;
  }
  &:nth-child(4) {
    width: 12%;
    min-width: 12%;
  }
  &:nth-child(5) {
    width: 40%;
    min-width: 40%;
  }
}

.date {
  color: #878f9b;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.open {
  background: #1c84ff;
  border-radius: 5px;
  padding: 5px 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}

.open-comment {
  background: #1c84ff;
  border-radius: 5px;
  padding: 3px 7px;
  color: #fff;
  margin-left: 6px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}

.solved {
  background: #58ae70;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.solved-comment {
  background: #58ae70;
  border-radius: 5px;
  padding: 3px 7px;
  color: #fff;
  margin-left: 6px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}

.inProgress {
  border: 1px solid #878f9b;
  border-radius: 5px;
  color: #878f9b;
  padding: 5px 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.inProgress-comment {
  border: 1px solid #878f9b;
  border-radius: 5px;
  padding: 3px 7px;
  color: #878f9b;
  margin-left: 6px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

.onhold {
  color: #878f9b;
  padding: 5px 10px;
  background: #eceef1;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.onhold-comment {
  border-radius: 5px;
  padding: 3px 7px;
  color: #878f9b;
  background: #eceef1;
  margin-left: 6px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

.rejected {
  background: #e74343;
  border-radius: 5px;
  padding: 5px 10px;
  color: #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.rejected-comment {
  border-radius: 5px;
  padding: 3px 7px;
  color: #fff;
  background: #e74343;
  margin-left: 6px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

.input-ticket-container {
  width: 95%;
  //margin-right: 17px;
  padding: 5px 10px;
  border: 1px solid #c4c6c9;
  .text-area {
    width: 100%;
  }
}

.question-parent-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  //align-items: flex-end;
}

.input-radio-container {
  width: 100%;
  margin-right: 17px;
  padding: 5px 10px;
}

input[name="ticket_type"] {
  accent-color: #f13333;
  color: #f13333;
}

.input-hint {
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}

.radio-right-margin {
  margin-right: 6px;
}

.checkbox-checked {
  accent-color: #f13333;
  color: #f13333;
}

.input-ticket {
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  border: 0px;
  outline: none;
}

.mt-top-ticket {
  margin-top: 14px;
}

.margin-bottom-ticket {
  margin-bottom: 24px;
}

.question-container {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
}

.ml-response-header {
  margin-left: 45px;
}

.ml-response-query {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #0f0f0f;
}

.ml-response {
  margin-left: 12px;
}

.response-reply {
  width: 70%;
  background: #f9f9fa;
  margin-top: 55px;
  padding-top: 18px;
  padding-bottom: 44px;
  padding-right: 99px;
  margin-bottom: 33px;
}

.ticket-submit {
  background: linear-gradient(137.94deg, #ff0062 13.02%, #ff4e00 67.33%);
  border-radius: 30px;
  padding: 10px 20px;
  margin-top: 16px;
  cursor: pointer;
}

.ticket-submit:disabled {
  background: #abafb4;
}

.submit {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin-left: 13px;
}

.submit-margin {
  margin-right: 17px;
}

.response-query {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #26303d;
}

.response-type {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #0f0f0f;
}
.profile-bg {
  background: #ff7878;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

.profile-bg-ticket {
  background: #ff7878;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.response-header {
  margin-left: 18px;
}

.response-width {
  margin-top: 36px;
  //width: 70%;
}

.initial {
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  color: #fff;
}

.responder {
  margin-left: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #26303d;
}

.reply {
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #26303d;
  text-align: right;
}

.response-date {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #abafb4;
  margin-left: 14px;
}

.no-ticket {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #000000;
  width: 354px;
}

.comment-container {
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #c4c6c9;
}

.status-container {
  width: 30%;
  padding: 5px 10px;
  border: 1px solid #c4c6c9;
}

.filter-type-container {
  margin-left: 6px;
  width: 75%;
  padding: 5px 10px;
  border: 1px solid #c4c6c9;
}

.assignee {
  margin-right: -99px;
  padding: 15px 22px 0px 0px;
  //background: rgba(255, 174, 121, 0.1);
}

.assignee-container {
  width: 25%;
  //margin-left: 16px;
  padding: 5px 10px;
  border: 1px solid #c4c6c9;
}

.input-assignee {
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  border: 0px;
  outline: none;
  background: #f9f9fa;
  //background: rgba(255, 174, 121, 0.1);
}

.input-comment {
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  border: 0px;
  outline: none;
  background: #f9f9fa;
}

.no-background {
  background: #fff;
}

.comment-submit {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.left-container {
  width: 100px;
  min-width: 100px;
  padding-right: 15px;
}

.reply-container {
  margin-top: 50px;
}
.response-container {
  margin-bottom: 30px;
  .user-name {
    font-weight: 600;
  }
  .user-comment,
  .user-name {
    font-size: 15px;
  }
}

.summary-container {
  //margin-top: 14px;
  width: 65%;
}

.query-container {
  margin-top: 30px;
  .query {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #878f9b;
  }
}

.attachment-container {
  margin-top: 20px;
  .attachment {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #878f9b;
  }
}

.attachment-block {
  margin-top: 6px;
  display: flex;
  flex-direction: row;
  padding: 5px 5px 5px 12px;
  align-items: center;
  border: 1px solid #c4c6c9;
  border-radius: 5px;
  cursor: pointer;
  .attachment-name {
    margin-left: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #878f9b;
  }
}

.loader {
  min-height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-text {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #ff2820;
}

.ticket-question {
  width: 140px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #0f0f0f;
}

.admin-wrap {
  height: 780px;
  overflow-y: scroll;
}

.m-bottom-search {
  margin-bottom: 24px;
}

.filter-container {
  width: 50%;
}

.load-more-container {
  min-width: 100%;
  margin-top: 16px;
}

.load-more {
  padding: 8px;
  border: 1px solid #c4c6c9;
  border-radius: 5px;
  cursor: pointer;
  .load-more-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #878f9b;
  }
}

.ticket-log-ticket-steps {
  padding-top: 6px;
  padding-bottom: 6px;
}

.steps-item {
  color: #ff2820;
}

.rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
  color: #ff2820 !important;
  border-color: #ff2820 !important;
  border-width: 2px;
}

.rs-steps-vertical .rs-steps-item-tail {
  border-left-width: 2px !important;
  border-color: #ff2820 !important;
}

.rs-steps-item-icon .rs-steps-item-icon-finish {
  color: #ff2820 !important;
}

.rs-steps-item-title {
  color: #000000;
  font-weight: 600;
}

.rs-steps-item-description {
  color: #878f9b;
  font-weight: 600;
}

.stats-holder{
  padding: 5px 30px;
  margin-right: 20px;
  margin-bottom: 20px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #c4c6c9;
  cursor: pointer;
  &.active {
    border: 1px solid #ff2820;
    background: rgba(#ff2820, 0.05);
  }
}

.title{
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.value{
  font-size: 14px;
  font-weight: 600;
}
.container {
  width: 100%;
  height: 52px;
  background: linear-gradient(
    90.06deg,
    #ff0080 -7.71%,
    #ff201f 54.19%,
    #fc7923 107.98%
  );
}

.tabs {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 120px;
  cursor: pointer;
}

.tabBorder {
  border-bottom: #fff 6px solid;
}

.tabtext {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.inputTextField {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border: 1px solid #c4c6c9;
  width: 40%;
}

.input {
  width: 90%;
  border: 0px;
  outline: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #abafb4;
}

.login {
  width: 95%;
  padding: 10px 15px;
  background: #ea4335;
  border: #fff;
  cursor: pointer;
  text-decoration: none;
}

.login-text {
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  width: 100%;
  display: block;
  text-decoration: none;
}

.login-container {
  width: 25%;
  height: 300px;
  border-radius: 3px;
  border: 1px solid rgba(222, 222, 222, 0.5);
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.main-container {
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-text {
  font-size: 30px;
  line-height: 35px;
  font-weight: 700;
  color: #000;
}

a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}

.profile {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.profileImage {
  background: linear-gradient(137.94deg, #ff0062 13.02%, #ff4e00 67.33%);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-left: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logout {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  background: #ffffff;
  margin-left: 8px;
  padding: 10px;
  cursor: pointer;
}

.settings {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #656c75;
  margin-left: 4px;
}

.name {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #6b717a;
}

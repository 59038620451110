.modalContainer {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
  overflow: auto;
  padding-top: 50px;
  background-color: rgba(0, 0, 0, 0.4);
}

.modalImageContainer {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modalContent {
  width: 45%;
  margin: 5% auto 5% auto;
  border: 1px solid #888;
  background-color: #fff;
  padding: 40px;
  height: 500px;
  overflow: auto;
}

.modalContentImage {
  width: 60%;
  margin: 5% auto auto auto;
  height: 620px;
  overflow: auto;
}

.modalHeading {
  font-weight: 700;
  font-size: 25px;
  line-height: 22px;
}

.modalClose {
  font-size: 40px;
  font-weight: bold;
  color: lightgrey;
  float: right;
  margin-top: -20px;
  cursor: pointer;
}

.modalClose:hover {
  color: black;
}

.modalImageClose {
  font-size: 40px;
  font-weight: bold;
  color: lightgrey;
  float: right;
  position: relative;
  top: 6px;
  right: 20px;
  cursor: pointer;
}

.modalImageClose:hover {
  color: black;
}

.modalBody {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-bottom: 32px;
}
